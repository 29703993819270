#photo_caro_wrapper {
	max-width: 1206px;
	display: flex;
	flex-grow: 1;
	margin: 0 auto 40px;
}

#photo_caro_container {
	display: flex;
	flex-grow: 1;
	position: relative;

	.chevron {
		height: 100%;
		width: 50px;
		position: relative;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			border-right: 5px solid $primary;
			border-bottom: 5px solid $primary;
			transform: translate(-50%, -50%) rotate(
				-45deg
			);
			top: 50%;
			left: 40%;
			height: 20px;
			width: 20px;
		}

		&.left {

			&:before {
				transform: translate(-50%, -50%) rotate(
					-225deg
				);
				left: 55%;
			}
		}
	}
}

#photo_caro {
	width: 100%;
	height: 723px;
	flex-grow: 1;

	.caro_inner {
		overflow: hidden;
		/* stylelint-disable selector-max-id, declaration-no-important */
		height: 100% !important;
		margin: 0;
	}

	.dots_container {
		height: 50px;
		margin-top: -50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, .5);
		position: absolute;
		width: 100%;
		bottom: 0;

		.caro_dot {
			height: 12px;
			width: 12px;
			border-radius: 500px;
			background-color: transparent;
			border: 1px solid #fff;

			&.active {
				background-color: #fff;
			}

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	.caro_slide {
		top: 0 !important;
		height: 100%;
		margin: 0 !important;
		/* stylelint-enable selector-max-id, declaration-no-important */
		background-position: center center;
		background-size: cover;
	}

	.chevron {
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}
}

@media screen and (max-width: 1250px) {

	#photo_caro {
		height: 550px;
	}
}

@media screen and (max-width: 950px) {

	#photo_caro {
		height: 475px;
	}
}

@media screen and (max-width: 750px) {

	#photo_caro {
		height: 400px;
	}

	#photo_caro_container {

		.chevron {
			position: absolute;
			top: 0;
			right: 0;

			&:before, &:after {
				border-right: 5px solid #fff;
				border-bottom: 5px solid #fff;
			}

			&.left {
				left: 0;
				z-index: 10;
			}
		}
	}
}

@media screen and (max-width: 500px) {

	#photo_caro {
		height: 360px;
	}
}