.map_includes_wrapper {

	.map_includes {
		display: flex;
		max-width: 1550px;
		width: 100%;
		margin: 0 auto 75px;
		box-sizing: border-box;
		padding: 0 53px;
	}

	.map_fac_info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.map_fac_phone {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.img_border {
			margin-left: -47px;
		}

		a {
			color: $primary;
			font-size: 30px;
			font-weight: 800;
			text-decoration: none;
			padding-left: .5em;
		}
	}

	.map_fac_info {

		margin: 0 0 40px 14px;
		font-size: 18px;

		h3 {
			font-size: 28px;
			font-weight: 800;
			margin-bottom: 10px;
		}

		&.email {

			a {
				color: $black;
				text-decoration: none;
				transition: color .3s;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}

#map {
	background: $accent;
	max-width: 1000px;
	width: 100%;
	min-height: 565px;
	margin-left: 135px;
}

@media screen and (max-width: 1550px) {

	.map_includes_wrapper {

		.map_fac_phone {
			margin-left: 16px;

			.img_border {
				margin-left: -59px;
			}

			a {
				font-size: 23px;
			}
		}
	}

	#map {
		margin-left: 20px;
	}
}

@media screen and (max-width: 1100px) {

	.map_includes_wrapper {

		.map_includes {

			flex-direction: column;
			padding: 0;
		}

		.map_fac_info_wrapper {
			align-items: center;
			text-align: center;
		}

		.map_fac_phone {
			margin-left: 0;

			.img_border {
				margin-left: 0;
			}
		}

		.map_fac_info {

			margin: 0 0 40px 0;
		}
	}

	#map {
		margin: 0 auto;
		max-width: 100%;
		min-height: 400px;
	}
}

@media screen and (max-width: 550px) {

	#map {
		min-height: 320px;
	}
}