#legality {
	margin-bottom: 25px;

	a {
		word-wrap: break-word;
	}

	h3 {
		font-size: 20px;
	}

	p, ul {
		line-height: 1.4;
		margin-bottom: 20px;
	}

	li {
		list-style: disc;
		margin-left: 25px;
	}
}