
#rates_table {
	max-width: 1550px;
	width: 100%;
	margin: 0 auto;
}

#facility_nav_wrapper {
	background-color: #1a1a1a;
	margin-top: -35px;
	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, .25);
}

#facility_nav {
	display: flex;
	justify-content: space-evenly;
	text-align: left;
	max-width: 925px;
	margin: 0 auto;

	li {
		width: 100%;
	}

	.nav_item {
		text-align: center;
		cursor: pointer;
		padding: 20px 10px;
		color: #fff;
		font-weight: 800;
		font-size: 18px;
		text-decoration: none;
		width: 100%;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			border-right: 2px solid $accent;
		}

		&:hover {
			color: lighten($primary, 15%);
		}
	}
}

#facility_rates {
	max-width: 1550px;
	width: 100%;
	margin: 0 auto;
	padding: 40px 0;
	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, .25);
}

#unit_table {
	width: 100%;
	max-width: 1335px;
	margin: 0 auto 15px;
	padding-top: 40px;

	.unavailable {
		display: none;
	}

	tr {
		width: 100%;
		box-sizing: border-box;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px 10px;

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}
	}

	td {
		vertical-align: middle;
	}

	.unit_special {
		margin-right: 30px;
		text-align: center;
		color: $primary;
		font-weight: 800;
		font-size: 16px;
	}

	.unit_features {
		margin-right: auto;

		ul {
			display: flex;
			justify-content: space-between;
		}

		li {
			margin-right: 30px;
		}

		img {
			margin-right: 5px;
		}
	}

	.unit_size {
		margin-right: 70px;

		.size {
			color: $black;
			font-weight: 800;
			font-size: 31px;
			margin: 0 auto 10px;
		}

		.type {
			font-size: 14px;
		}
	}

	.unit_buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		vertical-align: middle;

		.limited {
			font-size: 14px;
			margin-bottom: 10px;
			text-align: right;
			margin-right: 35px;
		}

		.unit_rent {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			margin-right: 30px;

			.was {
				display: flex;
				flex-direction: column;
				font-size: 16px;
				font-weight: 300;
				color: #666;
				position: relative;
				top: 3px;
				margin: 0 10px 0 0;

				.label {
					font-size: 12px;
					font-weight: 700;
					margin: 0 0 5px;
				}

				p {
					text-decoration: line-through;
				}
			}

			.now {
				display: flex;
				flex-direction: column;
				font-size: 26px;
				font-weight: 800;
				color: #000;

				.label {
					font-weight: 400;
					font-size: 15px;
					margin: 0 0 5px;
					align-self: center;
				}

				.price_label {
					display: flex;
					align-items: flex-end;
				}

				.now_month {
					display: inline-block;
					color: #5c5c5c;
					font-size: 14px;
					font-weight: 400;
					margin: 0 0 3px 2px;
				}
			}
		}

		.rate_reserve {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.btn-lin {
		text-decoration: none;
		font-size: 16px;
		font-weight: 800;
		color: #fff;
		padding: 10px 40px;
		background: linear-gradient(180deg, rgba(25, 115, 54, 1) 0%, rgba(25, 115, 54, 1) 49%, rgba(25, 115, 54, 1) 100%);
		border: 2px solid $primary;
		cursor: pointer;

		&:hover {
			border: 2px solid $secondary;
			background: linear-gradient(0deg, rgba(25, 115, 54, 1) 0%, rgba(25, 115, 54, 1) 100%);
		}
	}
}

#no_units, #no_units_available {
	text-align: center;
	padding: 35px;
	font-size: 18px;
	font-weight: 700;
	color: $primary;
	line-height: 1.8;
}

#no_units {
	display: none;
}

@media screen and (max-width: 1150px) {

	#unit_table {

		.unit_features {

			ul {
				flex-direction: column;
			}

			li {
				margin-right: 15px;
				padding-bottom: 10px;
			}
		}
	}
}

@media screen and (max-width: 950px) {

	#facility_nav_wrapper {
		margin-top: -20px;
	}

	#facility_nav {

		.nav_item {
			padding: 20px;
			white-space: nowrap;
		}
	}

	#unit_table {

		.unit_size {
			margin-right: 30px;
		}

		.unit_special {
			margin: 0 15px;
		}

		.unit_features {

			li {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
			}
		}

		.unit_row {
			padding: 15px 10px 25px;
		}
	}
}

@media screen and (max-width: 750px) {

	#unit_table {

		.unit_features {
			font-size: 14px;
		}

		.unit_special {
			margin: 0 10px;
		}

		.unit_size {
			margin-right: 10px;

			.size {
				font-size: 26px;
			}
		}

		.unit_buttons {

			.limited {
				margin-right: 7px;
			}

			.unit_rent {
				margin-right: 10px;
				flex-direction: column;

				.was {
					width: 100%;
					justify-content: center;
					margin-bottom: 10px;
				}

				.now {
					font-size: 24px;

					.label {
						font-size: 14px;
					}
				}
			}
		}

		.btn-lin {
			padding: 10px;
		}
	}

	#rates_disclaimer {
		padding: 15px;
	}
}

@media screen and (max-width: 500px) {

	#facility_nav {

		.nav_item {
			padding: 15px 20px;
		}
	}

	#unit_table {

		.unit_size {

			.size {
				font-size: 18px;
			}
		}

		.unit_special {
			font-size: 14px;
		}

		.unit_features {
			font-size: 12px;
		}

		.unit_buttons {

			.rate_reserve {
				flex-direction: column;
			}

			.unit_rent {
				margin-bottom: 20px;

				.now {
					font-size: 18px;
				}
			}
		}

		.btn-lin {
			font-size: 14px;
		}
	}
}