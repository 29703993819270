/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font: 'Montserrat', sans-serif;

body {
	font-family: $font;
}

/*********************
* Colors
*********************/
$primary: #197336;
$secondary: #197336;
$accent: #e3e3e3;
$black: #1a1a1a;
$white: #ffffff;
$gray: #f2f2f2;

/*********************
* Reusable Styles
*********************/

#skip_nav {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

#container {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow: hidden;
}

main {
	flex-grow: 1;
}

.title {
	font-size: 36px;
	font-weight: 800;
	text-align: center;
	color: $primary;
}

.subtitle {
	font-size: 20px;
	font-weight: 800;
	color: $black;
}

.img_border {
	box-shadow: 0 0 8px rgba(0, 0, 0, .5);
	border-radius: 50%;
	padding: .5em;
}

.btn {
	font-family: $font;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 20px;
	display: inline-block;
	text-decoration: none;

	cursor: pointer;
	transition: background .3s;

	color: #fff;
	background: $primary;

	&:hover {
		background: $accent;
		color: $primary;
	}
}

@media screen and (max-width: 750px) {

	.title {
		font-size: 28px;
	}

	.subtitle {
		font-size: 16px;
	}
}