#facility_top_wrapper {
	background-image: url(/dist/images/backgrounds/rates_header.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 515px;
	display: flex;
	align-items: center;
	border-bottom: 15px solid $accent;
}

#facility_top_container {
	display: flex;
	justify-content: flex-start;
	max-width: 1250px;
	margin: 0 auto;
	width: 100%;
	margin-top: -20px;
}

#facility_caro_wrapper {
	max-width: 640px;
	display: flex;
	flex-grow: 1;
	margin: 0 65px 0 0;
}

#facility_caro_container {
	display: flex;
	flex-grow: 1;

	.chevron {
		height: 100%;
		width: 50px;
		position: relative;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			border-right: 5px solid #fff;
			border-bottom: 5px solid #fff;
			transform: translate(-50%, -50%) rotate(
				-45deg
			);
			top: 50%;
			left: 40%;
			height: 20px;
			width: 20px;
		}

		&.left {

			&:before {
				transform: translate(-50%, -50%) rotate(
					-225deg
				);
				left: 55%;
			}
		}
	}
}

#facility_caro {
	width: 100%;
	height: 360px;
	flex-grow: 1;

	.caro_inner {
		overflow: hidden;
		/* stylelint-disable selector-max-id, declaration-no-important */
		height: 100% !important;
		margin: 0;
	}

	.caro_slide {
		top: 0 !important;
		height: 100%;
		margin: 0 !important;
		/* stylelint-enable selector-max-id, declaration-no-important */
		background-position: center center;
		background-size: cover;
	}

	.chevron {
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}
}

#facility_info {
	color: #fff;
	line-height: 1.2;
	padding: 0 10px;

	.img_border {
		background-color: #fff;
		padding: .2em;
		margin-left: -54px;
		margin-right: .3em;
		width: 29px;
		height: 29px;
	}

	h3 {
		font-size: 26px;
		font-weight: 800;
	}

	.office_hours, .access_hours, .address {
		font-size: 18px;
		margin-bottom: 25px;
	}

	.spider {
		font-size: 20px;
		text-decoration: none;
		color: #fff;

		img {
			margin-right: 5px;
		}

		&:hover {
			color: $primary;
		}
	}

	.phone {
		display: flex;
		align-items: center;
		font-size: 32px;
		font-weight: 800;
		text-decoration: none;
		color: #fff;

		&:hover {
			color: $primary;
		}
	}
}

@media screen and (max-width:1100px) {

	#facility_caro_wrapper {
		margin: 0 15px 0 0;
	}
}

@media screen and (max-width:950px) {

	#facility_top_container {
		flex-direction: column;
		justify-content: center;
		margin-top: 0;
	}

	#facility_caro_wrapper {
		margin: 20px auto 0;
		min-width: 375px;
		width: 100%;
	}

	#facility_info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		max-width: 500px;
		width: 100%;
		margin: 20px auto;

		.img_border {
			margin-left: 0;
			width: 0;
			height: 0;
		}

		h3 {
			font-size: 22px;
		}

		.phone {
			width: 45%;
			height: 20%;
			font-size: 22px;
			text-align: center;
			display: flex;

			img {
				display: none;
			}
		}

		.address, .office_hours, .access_hours {
			font-size: 16px;
			margin-bottom: 20px;
			width: 45%;
		}

		.spider {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	#facility_caro {
		height: 275px;
	}
}

@media screen and (max-width: 500px) {

	#facility_info {

		h3, .spider {
			font-size: 18px;
		}

		.address, .access_hours, .office_hours {
			font-size: 14px;
		}
	}
}