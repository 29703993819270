#site_footer, #site_footer_mobile {
	display: flex;
	justify-content: center;
	padding: 55px 10px;
	box-shadow: 2px 5px 10px rgba(#000, .25);

	.legality {
		display: flex;
	}

	li {
		margin: 0 18px;

		a {
			color: $black;
			text-decoration: none;
			transition: color .3s;

			&:hover {
				color: $primary;
			}

			&.pay-bill {
				background: $primary;
				color: $white;
				box-sizing: border-box;
				padding: 12px 15px;
				transition: background .3s;
				font-weight: 700;

				&:hover {
					background: $secondary;
				}
			}
		}
	}
}

#site_footer_mobile {
	display: none;
}

#site_footer_created_by {
	background: $primary;
	text-align: center;
	padding: 20px 0;
	position: relative;

	&:before {
		position: absolute;
		content: '';
		background: linear-gradient(to bottom, rgba($black, .35), transparent);
		top: 0;
		left: 0;
		width: 100%;
		height: 10px;
	}
}

/***>>Media Queries***/

@media screen and (max-width: 1250px) {

	#site_footer {

		li {
			margin: 0 10px;
		}
	}
}

@media screen and (max-width: 1100px) {

	#site_footer {

		li {
			margin: 0 6px;
		}
	}
}

@media screen and (max-width: 820px) {

	#site_footer {
		display: none;
	}

	#site_footer_mobile {
		display: flex;
		padding: 25px 35px;
	}
}