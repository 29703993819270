#facility {
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
}

#facility_promos {
	margin: 35px 0;
	text-align: center;
	padding: 0 20px;

	h2 {
		margin-bottom: 40px;
	}

	.promo {
		font-size: 18px;
		font-weight: 700;
		line-height: 1.4;
		margin-bottom: 10px;

		span {
			display: block;
			font-size: 14px;
			font-weight: normal;
		}
	}

	.promo_disclaimer {
		line-height: 1.4;
	}
}

#facility_about {
	max-width: 1550px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 70px;
	border-bottom: 1px solid $accent;

	h2 {
		margin: 40px 10px;
		padding-top: 20px;
	}

	p {
		font-size: 18px;
		line-height: 1.4;
		text-align: center;
		padding: 0 30px;
	}
}

#facility_features {
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
	padding-top: 70px;
}

#features_wrapper {
	display: flex;
	justify-content: space-evenly;

	.feature {
		text-align: center;

		img {
			margin-bottom: 20px;
			object-fit: contain;
			height: 50px;
		}

		p {
			font-size: 18px;
		}
	}
}

#features_cta {
	padding: 70px 0;
	display: flex;
	justify-content: center;
	align-items: center;

	h3 {
		font-size: 24px;
		font-weight: 800;
		color: $primary;
		line-height: 1.2;
		margin-right: 200px;
	}

	.btn-lin {
		white-space: nowrap;
		text-decoration: none;
		font-size: 20px;
		font-weight: 800;
		color: #fff;
		padding: 15px 50px;
		background: linear-gradient(to bottom, rgba($white, .35) 10%, transparent 45%);
		background-color: $primary;
		border: 1px solid $white;
		transform: translateZ(0);

		&:hover {
			background: $primary;
			z-index: 999;
		}
	}
}

#rates_disclaimer {
	text-align: center;
}

#chocks_notice {
	text-align: center;
	font-weight: 700;
	padding: 10px 0;
}

#rates_overlay {
	/* stylelint-disable selector-max-id */
	position: absolute;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 50;
	background: rgba(#000, .8);
	color: #fff;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s;
	padding-top: 40px;

	&.show {
		opacity: 1;
		pointer-events: all;
	}

	#rates_overlay_close {
		position: absolute;
		top: 30px;
		right: 30px;
		font-size: 36px;
		font-weight: 700;
		color: #fff;
		background: none;
		border: none;
		cursor: pointer;
	}

	#request_info {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	h2 {
		font-size: 36px;
		font-weight: 700;
	}

	#hp_wrap {
		position: fixed;
		left: -9999px;
	}

	#request_form_submit {
		border-radius: 5px;
		border: none;
		padding: 10px 70px;
		font-size: 22px;
	}
	/* stylelint-enable selector-max-id */
}

#rates_form_wrapper {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	box-sizing: border-box;

	label {
		position: fixed;
		left: -9999px;
	}

	input, textarea {
		padding: 10px 20px;
		font-size: 21px;
		border: 1px solid $primary;
		border-radius: 5px;
		margin: 10px 0;

		&.error {
			border: 1px solid $primary;
			box-shadow: inset 0px 0px 10px 3px rgba($primary, .5);
		}
	}

	textarea {
		font-family: $font;
	}

	.response_message {

		span {
			display: block;
			padding: 10px 10px;
			border-radius: 5px;
			margin-bottom: 10px;
		}

		.error {
			border: 1px solid #a00000;
			background: lighten(#a00000, .5);
		}

		.success {
			border: 1px solid #00ff00;
			color: #000;
			background: #88ff88;
		}
	}
}

/***>>>Media Queries***/

@media screen and (max-width: 980px) {

	#rates_overlay {

		.request_info {
			width: 90%;
		}
	}
}

@media screen and (max-width: 950px) {

	#features_cta {
		margin: 0 10px;

		h3 {
			margin-right: 20px;
		}
	}
}

@media screen and (max-width: 750px) {

	#facility_rates {
		padding: 0;
	}

	#facility_about {
		padding-bottom: 30px;

		p {
			font-size: 16px;
		}

		h2 {
			margin: 20px 10px;
		}
	}

	#facility_features {
		padding-top: 40px;
	}

	#features_wrapper {
		flex-wrap: wrap;

		.feature {
			flex: 33.33%;
			margin-bottom: 15px;

			p {
				font-size: 16px;
			}
		}
	}

	#features_cta {
		padding: 30px 0;

		.btn-lin {
			padding: 10px 45px;
		}

		h3 {
			text-align: center;
			font-size: 20px;
		}
	}
}

@media screen and (max-width: 640px) {

	#rates_overlay {

		h2 {
			margin-top: 80px;
		}
	}
}

@media screen and (max-width: 500px) {

	#features_cta {
		flex-direction: column;
		background-color: $accent;
		margin: 0 0 30px;
		padding: 15px 0 0;

		h3 {
			margin: 0;
			line-height: 1.5;
		}

		div {
			margin: 35px 10px;
		}
	}
}