#home_hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 238px 35px;
	background-image: url('/dist/images/backgrounds/home_hero.jpg');
	background-size: cover;
	margin-bottom: 80px;

	h2 {
		color: $white;
		font-size: 34px;
		font-weight: 400;
		text-align: center;
		line-height: 1.2;
		letter-spacing: 7px;

		span {
			display: block;
			font-size: 44px;
			font-weight: 800;
			margin-bottom: 40px;
			letter-spacing: 4px;
		}

		a {
			display: inline-block;
			border: 1px solid $white;
			background: linear-gradient(to bottom, rgba($white, .35) 10%, transparent 45%);
			background-color: $primary;
			font-size: 22px;
			font-weight: 800;
			max-width: 250px;
			width: 100%;
			position: relative;
			overflow: hidden;
			transform: translateZ(0);
			letter-spacing: 2px;

			&:hover {
				color: $white;

				&:after {
					opacity: 1;
					left: 0;
				}
			}

			&:after {
				position: absolute;
				content: '';
				height: 100%;
				width: 100%;
				background-color: rgba($secondary, .75);
				top: 0;
				left: -100%;
				opacity: 0;
				transition: opacity .3s, left .5s;
				z-index: -1;
			}
		}
	}

	.home_hero_btn_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		.sitemap {
			margin-top: 20px;
		}
	}
}

#home_features {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto 50px;
	max-width: 1550px;
	width: 100%;
	padding: 0 35px;
	box-sizing: border-box;

	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 16.667%;
		text-align: center;
		line-height: 1.4;
		font-size: 20px;
		margin-bottom: 30px;

		img {
			object-fit: contain;
			height: 50px;
			margin-bottom: 10px;
		}
	}
}

#home_about {

	display: flex;
	max-width: 1550px;
	width: 100%;
	margin: 0 auto 100px;
	padding: 0 35px;
	box-sizing: border-box;

	img {
		max-width: 740px;
		width: 100%;
	}

	.about_content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-left: 36px;

		h2 {
			color: $primary;
			font-size: 40px;
			font-weight: 800;
			margin-bottom: 30px;
		}

		p {
			font-size: 18px;
			line-height: 1.4;
		}
	}
}

#home_description_wrapper {

	display: flex;
	flex-direction: column;
	color: $white;
	background-image: url('/dist/images/backgrounds/home_rv_img_full_width.jpg');
	background-size: cover;
	border-top: 10px solid $accent;
	border-bottom: 10px solid $accent;
	box-sizing: border-box;
	padding: 155px 0 125px;
	margin-bottom: 68px;

	h2 {
		font-size: 36px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 38px;
	}
}

#home_description_container {

	display: flex;
	max-width: 1540px;
	width: 100%;
	margin: 0 auto;

	.desc_left, .desc_right {
		margin: 0 35px;
		width: 715px;
		font-size: 20px;
		line-height: 1.5;
	}
}

/***>>Media Queries***/

@media screen and (max-width: 1250px) {

	#home_about {

		.about_content {

			h2 {
				font-size: 26px;
			}
		}

		img {
			max-width: 600px;
		}
	}
}

@media screen and (max-width: 1100px) {

	#home_hero {
		padding: 90px 35px;
	}

	#home_features {

		li {

			width: 33.33%;
		}
	}

	#home_about {

		flex-direction: column;
		align-items: center;
		text-align: center;

		.about_content {
			margin-left: 0;
			align-items: center;
		}

		img {
			margin-bottom: 30px;
		}
	}

	#home_description_wrapper {

		padding: 35px;

		h2 {
			font-size: 28px;
		}
	}
}

@media screen and (max-width: 750px) {

	#home_hero {

		margin-bottom: 40px;

		h2 {
			font-size: 28px;

			span {
				font-size: 30px;
			}
		}
	}

	#home_features {

		margin: 0 auto;

		li {
			font-size: 18px;
		}
	}

	#home_about {

		margin: 0 auto 30px;
	}

	#home_description_wrapper {

		h2 {
			font-size: 22px;
		}
	}

	#home_description_container {

		flex-direction: column;

		.desc_left, .desc_right {

			width: auto;
			margin: 0;
			font-size: 18px;
		}

		.desc_left {
			margin-bottom: 30px;
		}
	}
}

@media screen and (max-width: 550px) {

	#home_hero {
		padding: 45px 35px;
	}

	#home_features {

		li {

			width: 50%;
		}
	}

	#home_about {

		.about_content {

			h2 {
				font-size: 18px;
			}

			p {
				font-size: 16px;
			}
		}
	}

	#home_description_container {

		.desc_left, .desc_right {
			font-size: 16px;
		}
	}
}