#contact_internal {
	display: flex;
	justify-content: space-between;
	max-width: 1550px;
	width: 100%;
	margin: 60px auto 75px;
	padding: 0 71px;
	box-sizing: border-box;
}

.contact_top_left {

	h2 {
		color: $primary;
		font-size: 36px;
		font-weight: 800;
		margin-bottom: 40px;
	}

	p {
		font-size: 18px;
		line-height: 1.8;
		width: 400px;
		margin-bottom: 30px;
	}

	.contact_phone {
		display: flex;
		align-items: center;

		.img_border {
			margin-right: .8em;
			margin-left: -61px;
		}

		img {
			display: inline-block;
		}

		a {
			color: $primary;
			font-size: 32px;
			font-weight: 800;
			text-decoration: none;
		}
	}
}

.contact_top_right {
	max-width: 1000px;
	width: 100%;
	margin-left: 35px;
}

#contact_form {

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.hp_wrap {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}

	.input_wrapper {
		display: flex;
	}

	input, textarea {
		border: 1px solid $accent;
		margin-bottom: 20px;
		padding: 16px;
		font-size: 18px;
		font-family: $font;

		&.error {
			border: 1px solid #ac0d0d;
		}
	}

	textarea {
		min-height: 105px;
	}

	.name_input, .phone_input {
		display: flex;
		flex-direction: column;
		max-width: calc(500px - 10px);
		width: 100%;

		label {
			margin-bottom: 10px;
		}
	}

	.phone_input {
		margin-left: 20px;
	}

	.email_input, .message_input {
		display: flex;
		flex-direction: column;
		width: 100%;

		label {
			margin-bottom: 10px;
		}
	}

	.message_input {
		margin-bottom: 10px;
	}

	.response_message {

		span {
			display: block;
			line-height: 1.4;
			margin-bottom: 30px;

			&.error {
				color: #ac0d0d;
			}

			&.success {
				color: #199619;
			}
		}
	}

	.btn {
		display: inline-block;
		border: 2px solid $secondary;
		background: linear-gradient(to bottom, rgba($white, .35) 10%, transparent 45%);
		background-color: $primary;
		font-size: 22px;
		font-weight: 800;
		max-width: 292px;
		width: 100%;
		position: relative;
		overflow: hidden;
		transform: translateZ(0);
		letter-spacing: 2px;

		&:hover {
			color: $white;

			&:after {
				opacity: 1;
				left: 0;
			}
		}

		&:after {
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			background-color: rgba($secondary, .75);
			top: 0;
			left: -100%;
			opacity: 0;
			transition: opacity .3s, left .5s;
			z-index: -1;
		}
	}
}

/***>>>Media Queries***/

@media screen and (max-width: 1100px) {

	#contact_form {

		.input_wrapper {
			flex-direction: column;
		}

		.name_input, .phone_input {
			max-width: 100%;
		}

		.phone_input {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 900px) {

	#contact_internal {

		flex-direction: column;

		.contact_top_left {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 35px;

			.contact_phone {

				.img_border {
					margin-left: 0;
				}
			}
		}

		.contact_top_right {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 500px) {

	#contact_internal {

		margin: 30px auto 75px;
	}

	.contact_top_left {

		h2 {
			margin-bottom: 20px;
		}

		p {
			font-size: 16px;
			padding: 0 35px;
			box-sizing: border-box;
			margin-bottom: 15px;
		}

		.contact_phone {

			a {
				font-size: 20px;
			}
		}
	}
}