#faq {
	margin-bottom: 30px;

	.title {
		line-height: 1.3;
	}

	.faq {
		display: flex;
		justify-content: space-between;

		div {
			padding: 0 10px;
			width: 44%;
		}
	}

	h3, p {
		margin-bottom: 20px;
	}

	p {
		font-size: 18px;
	}
}

/***>>>Media Queries***/

@media screen and (max-width: 750px) {

	#faq {
/* stylelint-disable */
		#internal_content {
			padding: 0;
		}
/* stylelint-enable */

		p {
			font-size: 16px;
		}
	}
}

@media screen and (max-width: 550px) {

	#faq {

		.faq {
			flex-direction: column;

			div {
				width: 95%;
			}
		}
	}
}