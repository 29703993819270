#internal_header_wrapper {
	margin: 70px 10px;
}

#internal_header {
	font-size: 36px;
	font-weight: 800;
	text-align: center;
	color: $primary;
}

#internal_content_wrapper {
	padding: 0 10px;
}

#internal_content {
	max-width: 1520px;
	margin: 0 auto;
	width: 100%;
	line-height: 1.2;
	padding: 0 10px;
	box-sizing: border-box;

	a {
		color: $primary;
		text-decoration: none;

		&:hover {
			color: lighten($color: $primary, $amount: 40%);
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width:750px) {

	#internal_header_wrapper {
		margin: 45px 10px;
	}
}