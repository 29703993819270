#features {
	margin-bottom: 65px;

	.features {
		display: flex;
		justify-content: space-evenly;
	}

	h3 {
		margin-bottom: 30px;
		font-size: 22px;
	}

	ul {
		font-size: 18px;
		padding: 0 10px;
		max-width: 760px;
	}

	li {
		list-style-type: disc;
		margin-left: 10px;
		line-height: 1.4;
	}
}

#policies {
	margin-bottom: 65px;

	.policies_cta {
		text-align: center;
		font-size: 18px;
		margin-bottom: 35px;
		line-height: 1.4;
	}

	.policies_wrapper {
		display: flex;
		justify-content: space-between;
		font-size: 18px;

		div {
			padding: 0 10px;
		}

		h3 {
			margin-bottom: 5px;
			font-size: 20px;
		}

		p {
			line-height: 1.4;
			margin-bottom: 20px;
		}

		ul {
			margin-bottom: 25px;
		}

		li {
			list-style-type: disc;
			margin-left: 20px;
		}
	}
}

@media screen and (max-width: 750px) {

	#features {
		margin-bottom: 40px;

		ul {
			font-size: 16px;
		}

		h3 {
			font-size: 20px;
		}
	}

	#policies {

		/* stylelint-disable */
		#internal_content {
			padding: 0;
		}
		/* stylelint-enable */

		.policies_cta {
			font-size: 16px;
		}

		.policies_wrapper {
			font-size: 16px;

			h3 {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 550px) {

	#features {

		.features {
			flex-direction: column;

			div:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}

	#policies {

		.policies_wrapper {
			flex-direction: column;
		}
	}
}