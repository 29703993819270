#site_mobile_toggle, #site_header_contact_mobile {
	display: none;
}

#site_header_wrapper {
	box-shadow: 0px 5px 10px rgba(#000, .25);
}

#site_header_container {
	margin: 0 auto;
	box-sizing: border-box;
	padding: 30px 35px;
	max-width: 1640px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
}

#site_header_nav {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

#site_header_contact {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0 0 46px;

	.top {
		color: $primary;
		font-size: 26px;
		margin-bottom: 10px;

		a {
			color: $primary;
			font-weight: 700;
			transition: color .3s;
			text-decoration: none;

			&:hover {
				color: $secondary;
			}
		}
	}

	.bottom {
		font-size: 20px;
	}
}

#site_nav {
	display: flex;
	align-items: center;

	.legality {
		display: flex;
	}

	li {

		a {
			color: $black;
			font-size: 16px;
			text-decoration: none;
			transition: color .3s;
			margin: 0 0 0 44px;

			&:hover {
				color: $primary;
			}

			&.pay-bill {
				background: $primary;
				color: $white;
				box-sizing: border-box;
				padding: 12px 15px;
				transition: background .3s;
				font-weight: 700;

				&:hover {
					background: $secondary;
				}
			}
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width: 1550px) {

	#site_nav {

		.legality {
			display: none;
		}
	}
}

@media screen and (max-width: 1250px) {

	#site_header_container {
		padding: 30px 10px;
	}

	#site_header_logo {

		img {
			max-width: 175px;
			width: 100%;
		}
	}

	#site_nav {

		li {

			a {
				margin: 0 0 0 24px;
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	#site_header_logo {

		img {
			max-width: 150px;
		}
	}
}

@media screen and (max-width: 950px) {

	#site_nav {

		li {

			a {
				font-size: 14px;
				margin: 0 0 0 10px;

				&.pay-bill {
					padding: 6px;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#site_mobile_toggle {

		display: block;
		position: absolute;
		top: 80px;
		right: 12px;

		span {
			display: block;
			position: relative;
			background: $primary;
			width: 35px;
			height: 3px;

			&:before, &:after {
				display: block;
				position: absolute;
				content: '';
				top: -10px;
				right: 0;
				background: $primary;
				width: 35px;
				height: 3px;
				transition: transform .3s;
			}

			&:after {
				top: 10px;
			}
		}

		&[open] {

			span {
				background: $white;

				&:before {
					transform: translateY(10px) rotate(45deg);
				}

				&:after {
					transform: translateY(-10px) rotate(-45deg);
				}
			}
		}
	}

	#site_header_container {
		padding: 20px 10px;
	}

	#site_header_contact {
		display: none;
	}

	#site_header_contact_mobile {
		display: block;

		a {
			display: block;
			position: absolute;
			top: 14px;
			right: 12px;
			width: 35px;

			img {
				max-width: 60px;
				width: 100%;
			}
		}
	}

	#site_nav {

		position: absolute;
		top: 108px;
		right: 0;
		min-width: 200px;
		background: $primary;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		box-sizing: border-box;
		opacity: 0;
		transform: translate(100%, 0);
		transition: opacity .5s, transform .5s;
		z-index: 10;

		&[open] {
			transform: translate(0, 0);
			opacity: 1;
		}

		li {

			a {

				color: $white;
				display: block;
				font-size: 18px;
				padding: 8px 0;

				&.pay-bill {
					display: block;
					background: none;
					margin-top: 5px;
					padding: 0;
				}
			}
		}
	}
}